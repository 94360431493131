import React, { useState } from "react";
import styled from "styled-components";
import { Grid } from "@mui/material";
import ReactPlayer from "react-player/lazy";
import handIcon from "../assets/Landing_Page_Button_Icons/Def_Hand_Icon.png";
import languageIcon from "../assets/Landing_Page_Button_Icons/Def_Language_Icon.png";
import handIconSel from "../assets/Landing_Page_Button_Icons/Sel_Hand_Icon.png";
import languageIconSel from "../assets/Landing_Page_Button_Icons/Sel_Language_Icon.png";
import volumemute from "../assets/Landing_Page_Button_Icons/volumemute.png";
import volumeunmute from "../assets/Landing_Page_Button_Icons/volumeunmute.png";

const CoAccessibilityComponent = (props) => {
  const [mute, setMute] = useState(true);
  const [isSignLanguage, setIsSignLanguage] = useState(false);
  const [isTranslated, setIsTranslated] = useState(false);
  const [isPlayingCoAccessibility, setIsPlayingCoAccessibility] =
    useState(false);

  const onMute = () => {
    setMute(!mute);
  };

  const handleLanguageLogo = (e) => {
    e.preventDefault();
    setIsTranslated(true);
    setIsSignLanguage(false);
    setIsPlayingCoAccessibility(true);
  };

  const handleHandLanguageLogo = (e) => {
    e.preventDefault();
    setIsSignLanguage(true);
    setIsTranslated(false);
    setIsPlayingCoAccessibility(true);
  };

  return (
    <>
      <div
        style={{
          alignItems: "center",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Group18>
          {isSignLanguage ? (
            <ReactPlayer
              playing={isPlayingCoAccessibility}
              width="100%"
              height="100%"
              volume={1}
              muted={mute}
              url={
                "https://firebasestorage.googleapis.com/v0/b/copal-e5764.appspot.com/o/public%2FCoAccessibility_SignLanguage.mp4?alt=media&token=a0dfe314-60a1-4356-ba4a-da51dbbcb6b4"
              }
            />
          ) : isTranslated ? (
            <ReactPlayer
              playing={isPlayingCoAccessibility}
              width="100%"
              height="100%"
              volume={1}
              muted={mute}
              url={
                "https://firebasestorage.googleapis.com/v0/b/copal-e5764.appspot.com/o/public%2FCoAccessibility_MultiLanguageSubtitles.mp4?alt=media&token=fc2ff532-ccc3-430d-ac1a-4d769aeea093"
              }
            />
          ) : (
            <ReactPlayer
              playing={true}
              loop={true}
              width="100%"
              height="100%"
              volume={1}
              muted={mute}
              config={{
                file: {
                  attributes: {
                    autoPlay: true,
                  },
                },
              }}
              url={
                "https://firebasestorage.googleapis.com/v0/b/copal-e5764.appspot.com/o/public%2FCoAccessibility_Regular.mp4?alt=media&token=e1723bf4-a537-477d-8622-073fe7ed9a34"
              }
            />
          )}
        </Group18>
      </div>
      <Grid
        container
        direction="row"
        md={12}
        sx={{
          paddingLeft: "20%",
        }}
      >
        <Grid
          item
          aria-label="Mute/Unmute"
          md={3}
          style={{
            position: "absolute",
            width: "40px",
            height: "40px",
            color: "black",
            marginRight: "25px",
            marginTop: "22px",
          }}
          onClick={onMute}
        >
          {mute ? (
            <img
              name="Volume Mute"
              alt="Volume Mute"
              src={volumemute}
              style={{
                width: "40px",
                height: "40px",
                cursor: "pointer",
              }}
            />
          ) : (
            <img
              name="Volume Unmute"
              alt="Volume Unmute"
              src={volumeunmute}
              style={{
                width: "40px",
                height: "40px",
                cursor: "pointer",
              }}
            />
          )}
        </Grid>
        <Grid item md={9}>
          <Grid
            container
            direction="row"
            style={{
              display: "flex",
              alignItems: "center",
              width: "200px",
              height: "75.73px",
              background: "#f7f7f7",
              borderRadius: "25px",
              marginLeft: "50px",
              border: "0.5px solid black",
            }}
          >
            <Grid
              item
              md={5}
              style={{
                fontFamily: "Poppins",
                fontStyle: "normal",
                fontWeight: 700,
                fontSize: "13px",
                lineHeight: "40px",
                letterSpacing: "0.424856px",
                color: "black",
                opacity: "0.5",
              }}
            >
              &nbsp;&nbsp;&nbsp;&nbsp;Try it Out
            </Grid>
            <Grid
              item
              container
              direction="row"
              md={7}
              style={{
                width: "43.73px",
                height: "43.73px",
                justifyContent: "space-between",
                paddingRight: "15px",
              }}
            >
              <Grid item>
                {isTranslated ? (
                  <img
                    name="Translation Enabled"
                    alt="Translation Enabled"
                    src={languageIconSel}
                    style={{
                      width: "43.73px",
                      height: "43.73px",
                      cursor: "pointer",
                    }}
                    onClick={handleLanguageLogo}
                  />
                ) : (
                  <img
                    name="Translation Disabled"
                    alt="Translation Disabled"
                    src={languageIcon}
                    style={{
                      width: "43.73px",
                      height: "43.73px",
                      cursor: "pointer",
                    }}
                    onClick={handleLanguageLogo}
                  />
                )}
              </Grid>
              <Grid item>
                {isSignLanguage ? (
                  <img
                    name="Sign Language Enabled"
                    alt="Sign Language Enabled"
                    src={handIconSel}
                    style={{
                      width: "43.73px",
                      height: "43.73px",
                      cursor: "pointer",
                    }}
                    onClick={handleHandLanguageLogo}
                  />
                ) : (
                  <img
                    name="Sign Language Disabled"
                    alt="Sign Language Disabled"
                    src={handIcon}
                    style={{
                      width: "43.73px",
                      height: "43.73px",
                      cursor: "pointer",
                    }}
                    onClick={handleHandLanguageLogo}
                  />
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default CoAccessibilityComponent;

const Group18 = styled.div`
  width: 500px;
  position: relative;
`;
