import React from "react";
import { Card, CardContent, Typography, IconButton } from "@mui/material";
import { makeStyles } from "@mui/styles";
import LinkedInIcon from "@mui/icons-material/LinkedIn";

const useStyles = makeStyles((theme) => ({
  card: {
    textAlign: "center",
    margin: "7%",
    border: "0.5px ridge black",
    boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
    transition: "transform 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275)", // Slower and smoother transition
    "&:hover": {
      transform: "scale(1.05)",
    },
    borderRadius: "5%",
  },
}));

function TeamMember({ name, designation, description, imageUrl, linkedinUrl }) {
  const classes = useStyles();

  return (
    <Card className={classes.card}>
      <img
        src={imageUrl}
        style={{
          borderRadius: "50%",
          width: "150px",
          height: "150px",
          margin: "0 auto",
          marginTop: "20px",
        }}
      />
      <CardContent>
        <Typography variant="h6">
          {name}
          &nbsp;
          <IconButton
            href={linkedinUrl}
            target="_blank"
            rel="noopener noreferrer"
            aria-label={`${name}'s LinkedIn`}
          >
            <LinkedInIcon />
          </IconButton>
        </Typography>
        <Typography variant="subtitle1" sx={{ color: "grey" }}>
          {designation}
        </Typography>
        <Typography variant="body2" sx={{ marginTop: "20px" }}>
          {description}
        </Typography>
      </CardContent>
    </Card>
  );
}

export default TeamMember;
