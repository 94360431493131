import React, { useState } from "react";
import "./faq.css";
import NavBar from "./Navbar/NavBar";
import FAQ_LOGO from "../images/daily-woman-thinking-over-a-question.png";

const FAQs = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  const faqData = [
    {
      question: "Do FERPA privacy laws apply to videos?",
      answer:
        "As per the most recent guidelines from the Department of Education, video recordings or the classrooms constitute educational records especially if they have student faces, voices, and names in the video.",
    },
    {
      question: "What are the Universities doing for compliance?",
      answer:
        "Most universities now have policies and guidelines for professors informing them how to share the videos. In simple terms, policy states that either get consent or do one of the following: delete the video or anonymize the video. Unfortunately, there were no simple and easy to use tools which could help faculty achieve these objectives. CoPrivacy makes it as simple as uploading and downloading a video with heavy lifting being done by the AI.",
    },
    {
      question: "What are some of the use cases for faculty?",
      answer:
        "YThere are many use cases for faculty. For example, sharing guest lectures across different sections, students best presentations, student feedback, sharing videos with colleagues.",
    },
    {
      question: "Where does our data reside and how secure is it?",
      answer:
        "The data resides securely through the firewalls of the university’s cloud database. Additionally our tool is VPAT/HECVAT compliant following the highest security practices.",
    },
    {
      question: "What is CoPrivacy?",
      answer:
        "CoPrivacy is an AI software by CoPal that analyzes videos using advanced neural net, AI, and machine learning techniques to detect and protect against privacy violations by recognizing and anonymizing faces, voices, and personal information in accordance with relevant regulations.",
    },
    {
      question: "What is the goal of FERPA Tool?",
      answer:
        "Facilitate faculty awareness of privacy laws and provide user-friendly tools for SJSU online program professors to confidently develop courses without FERPA compliance concerns.",
    },
    {
      question: "How can a university use the tool?",
      answer:
        "The FERPA tool will be integrated with the Learning Management System of your university (eg canvas, Blackboard) and the professor can grant access as needed to the student assistants.",
    },
  ];

  const toggleAccordion = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <>
      <NavBar />
      <div className="faq-container">
        <div className="my-4 py-4" data-aos="zoom-in">
          <h2 className="my-2 text-center text-3xl text-blue-900 uppercase font-bold">
            FAQ
          </h2>

          <div className="flex justify-center">
            <div className="w-24 border-b-4 border-blue-900"></div>
          </div>
          {/* <h2 className="mt-4 mx-12 text-center text-xl lg:text-2xl font-semibold text-blue-900">
            You can reach Gurmeet Naroola at 408-931-0944 or Nitin Aggarwal at
            408-834-0167
          </h2> */}
          <img src={FAQ_LOGO} />
        </div>
        <div className="faq-columns" data-aos="fade-up" data-aos-delay="100">
          <div className="faq-column">
            {faqData
              .slice(0, Math.ceil(faqData.length / 2))
              .map((item, index) => (
                <div key={index} className="faq-item">
                  <div
                    className={`faq-question ${
                      activeIndex === index ? "active" : ""
                    }`}
                    onClick={() => toggleAccordion(index)}
                  >
                    {item.question}
                    <i
                      className={`fa ${
                        activeIndex === index
                          ? "fa-chevron-up"
                          : "fa-chevron-down"
                      }`}
                    />
                  </div>
                  {activeIndex === index && (
                    <div className="faq-answer">{item.answer}</div>
                  )}
                </div>
              ))}
          </div>
          <div className="faq-column">
            {faqData.slice(Math.ceil(faqData.length / 2)).map((item, index) => (
              <div
                key={index + Math.ceil(faqData.length / 2)}
                className="faq-item"
              >
                <div
                  className={`faq-question ${
                    activeIndex === index + Math.ceil(faqData.length / 2)
                      ? "active"
                      : ""
                  }`}
                  onClick={() =>
                    toggleAccordion(index + Math.ceil(faqData.length / 2))
                  }
                >
                  {item.question}
                  <i
                    className={`fa ${
                      activeIndex === index + Math.ceil(faqData.length / 2)
                        ? "fa-chevron-up"
                        : "fa-chevron-down"
                    }`}
                  />
                </div>
                {activeIndex === index + Math.ceil(faqData.length / 2) && (
                  <div className="faq-answer">{item.answer}</div>
                )}
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default FAQs;
