import React from "react";
import NavBar from "./Navbar/NavBar";
import { Typography, Grid, ListItem, Paper } from "@mui/material";

function Patents() {
  return (
    <>
      <NavBar />
      <div className="my-4 py-4" data-aos="zoom-in">
        <h2
          className="my-2 text-center text-3xl text-blue-900 uppercase font-bold"
          style={{ marginTop: "100px" }}
        >
          patents
        </h2>
        <div className="flex justify-center">
          <div className="w-24 border-b-4 border-blue-900"></div>
        </div>
        <h2 className="mt-4 mx-12 text-center text-xl lg:text-2xl font-semibold text-blue-900">
          At Copal, we pride ourselves on our commitment to innovation and have
          successfully secured provisional patents
        </h2>
      </div>
      <Grid
        container
        spacing={3}
        sx={{ margin: "15px" }}
        data-aos="fade-up"
        data-aos-delay="100"
      >
        {/* Left Column (Image) */}
        <Grid item xs={12} md={6}>
          <img
            src="https://thumbs.dreamstime.com/b/patent-concept-young-man-patent-concept-young-man-holding-his-hand-173050036.jpg"
            alt="Patents"
            style={{ maxWidth: "100%", height: "auto", borderRadius: "5%" }}
          />
        </Grid>

        {/* Right Column (Patents) */}
        <Grid item xs={12} md={6}>
          <Paper
            elevation={2}
            sx={{
              padding: "16px",
              marginBottom: "16px",
              width: "90%",
              marginTop: "30px",
            }}
          >
            <ListItem>
              <div>
                <Typography
                  variant="h6"
                  style={{ marginBottom: "8px", textDecoration: "underline" }}
                >
                  Extraction
                </Typography>
                <Typography variant="body2">
                  Audiovisual parsing and augmentation of lecture material – we
                  jointly parse the video and audio. We parse the input video
                  stream to all its unique components and combine it with voice
                  and audio input to index and augment the content / learning
                  experience to produce the desired results.
                </Typography>
              </div>
            </ListItem>
          </Paper>
          <Paper
            elevation={3}
            style={{
              padding: "16px",
              marginBottom: "16px",
              width: "90%",
              marginTop: "50px",
            }}
          >
            <ListItem>
              <div>
                <Typography
                  variant="h6"
                  style={{ marginBottom: "8px", textDecoration: "underline" }}
                >
                  Accessible - Retrieval
                </Typography>
                <Typography variant="body2">
                  We build a knowledge Eco-system from all data sources. The
                  transcript from audio, video as well as additional references.
                  In addition to the knowledge available through the provided
                  material we also enrich the knowledge graph with internet
                  search. We then build a reasoning chain on top to make the
                  knowledge indexable and searchable.
                </Typography>
              </div>
            </ListItem>
          </Paper>
        </Grid>
      </Grid>
    </>
  );
}

export default Patents;
