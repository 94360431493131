import React from "react";
import Customers from "../landing_new/Customers";
import NavBar from "./Navbar/NavBar";

const Services = () => {
  return (
    <>
      <NavBar />
      <div id="services" className="bg-white-100 py-12">
        <section data-aos="zoom-in-down">
          <div className="my-4 py-4">
            <h2 className="my-2 text-center text-3xl text-blue-900 uppercase font-bold">
              customers and partners
            </h2>

            <div className="flex justify-center">
              <div className="w-24 border-b-4 border-blue-900"></div>
            </div>
            <h2 className="mt-4 mx-12 text-center text-xl lg:text-2xl font-semibold text-blue-900">
              We are proud to work with a diverse group of customers and
              partners, including:
            </h2>
          </div>
        </section>
        <Customers />
      </div>
    </>
  );
};

export default Services;
