import React from "react";
import { Link } from "react-router-dom";
import Testimonials from "./Testimonials";
// import BG_IMAGE from "../images/bg_image.jpg";

const Hero = () => {
  return (
    <>
      <div
        style={{
          background: "white",
          margin: "auto",
          overflow: "hidden",
          marginLeft: "1rem",
          marginRight: "1rem",
          marginTop: "2rem",
          padding: "0.5rem",
          paddingLeft: "3rem",
          paddingRight: "3rem",
          height: "83.33%",
        }}
        data-aos="zoom-in"
      >
        <div className="flex flex-col lg:flex-row py-8 justify-between text-center lg:text-left">
          <div
            className="lg:w-1/2 flex flex-col justify-center"
            data-aos="zoom-in"
            data-aos-delay="100"
          >
            <h1 className="mb-5 md:text-5xl text-3xl font-bold text-blue-900">
              AI Tools for interactive digital learning
            </h1>
            <div className="text-xl font-semibold tracking-tight mb-5 text-gray-500">
              Copal.ai is an ed-tech startup committed to empowering educators
              and content creators with AI-driven tools that help to create
              engaging, personalized, and inclusive learning experiences while
              ensuring compliance with relevant data security regulations.
            </div>
            <div className="mb-4 space-x-0 md:space-x-2 md:mb-8">
              <Link
                to="/contact"
                className="text-white bg-blue-900 hover:bg-blue-800 inline-flex items-center justify-center w-full px-6 py-3 my-4 text-lg shadow-xl rounded-2xl sm:w-auto sm:mb-0"
              >
                Learn more
                <svg
                  className="w-4 h-4 ml-1"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fillRule="evenodd"
                    d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                    clipRule="evenodd"
                  ></path>
                </svg>
              </Link>
            </div>
          </div>
          <div
            className="flex lg:justify-end w-full lg:w-1/2"
            data-aos="fade-up"
            data-aos-delay="100"
          >
            <Testimonials />
          </div>
        </div>
      </div>
    </>
  );
};

export default Hero;
