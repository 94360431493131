import React from "react";
import NavBar from "./Navbar/NavBar";
import { Grid, ListItem, Paper, Link, Typography } from "@mui/material";

function Media() {
  return (
    <>
      <NavBar />
      <div className="my-4 py-4" data-aos="zoom-in">
        <h2
          className="my-2 text-center text-3xl text-blue-900 uppercase font-bold"
          style={{ marginTop: "100px" }}
        >
          media coverage
        </h2>
        <div className="flex justify-center">
          <div className="w-24 border-b-4 border-blue-900"></div>
        </div>
        <h2 className="mt-4 mx-12 text-center text-xl lg:text-2xl font-semibold text-blue-900">
          At Copal, we're dedicated to making headlines that captivate and
          inform.
        </h2>
      </div>
      <Grid
        container
        spacing={3}
        sx={{ margin: "15px" }}
        data-aos="fade-up"
        data-aos-delay="100"
      >
        {/* Left Column (Image) */}
        <Grid item xs={12} md={6}>
          <img
            src="https://www.zionandzion.com/wordpress/wp-content/uploads/2018/05/9-tips-for-giving-a-great-media-interview.jpg"
            alt="Media"
            style={{ maxWidth: "100%", height: "auto", borderRadius: "5%" }}
          />
        </Grid>

        {/* Right Column (Media) */}
        <Grid item xs={12} md={6}>
          <Paper
            elevation={2}
            sx={{
              padding: "16px",
              marginBottom: "16px",
              width: "90%",
              marginTop: "75px",
            }}
          >
            <ListItem>
              <div>
                <Link
                  href="https://tesig.aib.world/insight-digital-teaching-is-more-than-just-videotaping-lectures/"
                  sx={{
                    textDecoration: "underline",
                    color: "black",
                    fontWeight: "bold",
                    cursor: "pointer",
                    transition: "all 0.2s ease-in-out",
                  }}
                  target="_blank"
                >
                  <Grid container direction="row">
                    <Grid item>
                      <Typography>
                        Insight: Digital teaching is more than just videotaping
                        lectures
                      </Typography>
                    </Grid>
                    <Grid item>
                      <img
                        width="24"
                        height="24"
                        src="https://img.icons8.com/material-outlined/24/external-link.png"
                        alt="external-link"
                        style={{
                          marginLeft: "10px",
                        }}
                      />
                    </Grid>
                  </Grid>
                </Link>
              </div>
            </ListItem>
          </Paper>
          <Paper
            elevation={3}
            style={{
              padding: "16px",
              marginBottom: "16px",
              width: "90%",
              marginTop: "50px",
            }}
          >
            <ListItem>
              <div>
                <Link
                  href="https://www.fau.de/2021/02/news/studium/das-geheimnis-der-perfekten-online-vorlesung/"
                  sx={{
                    textDecoration: "underline",
                    color: "black",
                    fontWeight: "bold",
                    cursor: "pointer",
                    transition: "all 0.2s ease-in-out",
                  }}
                  target="_blank"
                >
                  <Grid container direction="row">
                    <Grid item>
                      <Typography>
                        This is how varied, digital teaching is achieved
                      </Typography>
                    </Grid>
                    <Grid item>
                      <img
                        width="24"
                        height="24"
                        src="https://img.icons8.com/material-outlined/24/external-link.png"
                        alt="external-link"
                        style={{
                          marginLeft: "10px",
                        }}
                      />
                    </Grid>
                  </Grid>
                </Link>
              </div>
            </ListItem>
          </Paper>
        </Grid>
      </Grid>
    </>
  );
}

export default Media;
