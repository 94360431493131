import React from "react";
import { Button } from "@mui/material";
import NavBar from "./Navbar/NavBar";
import CoPrivacyComponent from "./CoPrivacyComponent";
import CoAccessibilityComponent from "./CoAccessibilityComponent";
import Dialog from "@mui/material/Dialog";
import Paper from "@mui/material/Paper";
import Draggable from "react-draggable";

function PaperComponent(props) {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}

const Portfolio = () => {
  const [open, setOpen] = React.useState(false);
  const [privacyOpen, setPrivacyOpen] = React.useState(false);
  const [videoBookOpen, setVideoBookOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handlePrivacyClickOpen = () => {
    setPrivacyOpen(true);
  };

  const handlePrivacyClose = () => {
    setPrivacyOpen(false);
  };

  const handleVideoBookClickOpen = () => {
    setVideoBookOpen(true);
  };

  const handleVideoBookClose = () => {
    setVideoBookOpen(false);
  };

  return (
    <>
      <NavBar />
      <div className="my-4 py-4" id="portfolio" style={{ marginTop: "75px" }}>
        <div data-aos="zoom-in">
          <h2 className="my-2 text-center text-3xl text-blue-900 uppercase font-bold">
            Products
          </h2>
          <div className="flex justify-center">
            <div className="w-24 border-b-4 border-blue-900 mb-8"></div>
          </div>
          <h2 className="mx-12 text-center text-xl lg:text-2xl font-semibold text-blue-900">
            We have developed 3 key products that are ready for deployment
          </h2>
        </div>

        <section>
          <div className="max-w-6xl mx-auto px-4 sm:px-6">
            <div className="py-12 md:py-20">
              {/* Items */}
              <div
                // className="grid gap-20"
                style={{
                  display: "grid",
                  gap: "10rem",
                }}
              >
                {/* 1st item */}
                <div className="md:grid md:grid-cols-12 md:gap-6 items-center">
                  {/* Image */}
                  <div
                    className="max-w-xl md:max-w-none md:w-full mx-auto md:col-span-5 lg:col-span-6 mb-8 md:mb-0 md:order-1"
                    data-aos="fade-up"
                  >
                    <Button
                      size="large"
                      variant="outlined"
                      target="_blank"
                      onClick={(e) => {
                        e.preventDefault();
                        window.open(
                          "https://drive.google.com/file/d/1FhePkhNT4Zh0tjtdlKOOvva3nyzD8Xy2/view?ts=6503afaf"
                        );
                      }}
                      sx={{ marginLeft: "75px" }}
                    >
                      View Full Demo
                    </Button>
                    <span
                      style={{
                        cursor: "pointer",
                      }}
                    >
                      <>
                        <Button
                          size="large"
                          variant="outlined"
                          target="_blank"
                          onClick={handlePrivacyClickOpen}
                          sx={{ marginLeft: "35px" }}
                        >
                          View Key Features
                        </Button>
                        <Dialog
                          open={privacyOpen}
                          onClose={handlePrivacyClose}
                          PaperComponent={PaperComponent}
                          aria-labelledby="draggable-dialog-title"
                        >
                          <CoPrivacyComponent />
                        </Dialog>
                      </>
                    </span>
                  </div>
                  {/* Content */}
                  <div
                    className="max-w-xl md:max-w-none md:w-full mx-auto md:col-span-7 lg:col-span-6"
                    data-aos="fade-right"
                  >
                    <div className="md:pr-4 lg:pr-12 xl:pr-16">
                      <div
                        style={{
                          fontSize: "25px",
                          fontWeight: "bold",
                          marginBottom: "15px",
                        }}
                      >
                        Privacy FERPA Tool
                      </div>

                      <ul className="text-lg text-gray-400 -mb-2">
                        <li className="flex items-center mb-2">
                          <svg
                            className="w-3 h-3 fill-current text-green-500 mr-2 shrink-0"
                            viewBox="0 0 12 12"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
                          </svg>
                          <span>Anonymization of faces, voices, text</span>
                        </li>
                        <li className="flex items-center mb-2">
                          <svg
                            className="w-3 h-3 fill-current text-green-500 mr-2 shrink-0"
                            viewBox="0 0 12 12"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
                          </svg>
                          <span>AI driven consent management system</span>
                        </li>
                        <li className="flex items-center">
                          <svg
                            className="w-3 h-3 fill-current text-green-500 mr-2 shrink-0"
                            viewBox="0 0 12 12"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
                          </svg>
                          <span>
                            LMS integration for seamless transfer of videos
                          </span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>

                {/* 2nd item */}
                <div className="md:grid md:grid-cols-12 md:gap-6 items-center">
                  {/* Image */}
                  <div
                    className="max-w-xl md:max-w-none md:w-full mx-auto md:col-span-5 lg:col-span-6 mb-8 md:mb-0 rtl"
                    data-aos="fade-up"
                  >
                    <Button
                      size="large"
                      variant="outlined"
                      target="_blank"
                      onClick={(e) => {
                        e.preventDefault();
                        window.open("https://mti.copal.ai/", "_blank");
                      }}
                    >
                      View Full Demo
                    </Button>
                    <span
                      style={{
                        cursor: "pointer",
                      }}
                    >
                      <>
                        <Button
                          size="large"
                          variant="outlined"
                          target="_blank"
                          onClick={handleVideoBookClickOpen}
                          sx={{ marginLeft: "35px" }}
                        >
                          View Key Features
                        </Button>
                        <Dialog
                          open={videoBookOpen}
                          onClose={handleVideoBookClose}
                          PaperComponent={PaperComponent}
                          aria-labelledby="draggable-dialog-title"
                        >
                          <iframe
                            width="500"
                            height="325"
                            src="https://drive.google.com/file/d/1qVDse2D1DqjYu23R-Rrs0mxmAY0uwgGb/preview"
                            title="CoPal Product Introduction and Demo"
                            frameborder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                            allowfullscreen
                          ></iframe>
                        </Dialog>
                      </>
                    </span>
                  </div>
                  {/* Content */}
                  <div
                    style={{
                      gridColumn: "span 6 / span 6",
                      width: "100%",
                      maxWidth: "none",
                      marginLeft: "175px",
                    }}
                    data-aos="fade-right"
                  >
                    <div
                      style={{
                        paddingRight: "1rem",
                      }}
                    >
                      <div
                        style={{
                          fontSize: "25px",
                          fontWeight: "bold",
                          marginBottom: "15px",
                        }}
                      >
                        Smart Videobook
                      </div>

                      <ul
                        style={{
                          marginBottom: "-0.5rem",
                          fontSize: "1.125rem",
                          lineHeight: "1.75rem",
                          color: "#9CA3AF",
                        }}
                      >
                        <li
                          style={{
                            display: "flex",
                            marginBottom: "0.5rem",
                            alignItems: "center",
                          }}
                        >
                          <svg
                            style={{
                              marginRight: "0.5rem",
                              shrink: "0",
                              width: "0.75rem",
                              height: "0.75rem",
                              color: "#10B981",
                              fill: "currentColor",
                            }}
                            viewBox="0 0 12 12"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
                          </svg>
                          <span>Content interlinking</span>
                        </li>
                        <li
                          style={{
                            display: "flex",
                            marginBottom: "0.5rem",
                            alignItems: "center",
                          }}
                        >
                          <svg
                            style={{
                              marginRight: "0.5rem",
                              shrink: "0",
                              width: "0.75rem",
                              height: "0.75rem",
                              color: "#10B981",
                              fill: "currentColor",
                            }}
                            viewBox="0 0 12 12"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
                          </svg>
                          <span>Transcription/translation</span>
                        </li>
                        <li
                          style={{
                            display: "flex",
                            marginBottom: "0.5rem",
                            alignItems: "center",
                          }}
                        >
                          <svg
                            style={{
                              marginRight: "0.5rem",
                              shrink: "0",
                              width: "0.75rem",
                              height: "0.75rem",
                              color: "#10B981",
                              fill: "currentColor",
                            }}
                            viewBox="0 0 12 12"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
                          </svg>
                          <span>AI generated quizzes</span>
                        </li>
                        <li
                          style={{
                            display: "flex",
                            marginBottom: "0.5rem",
                            alignItems: "center",
                          }}
                        >
                          <svg
                            style={{
                              marginRight: "0.5rem",
                              shrink: "0",
                              width: "0.75rem",
                              height: "0.75rem",
                              color: "#10B981",
                              fill: "currentColor",
                            }}
                            viewBox="0 0 12 12"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
                          </svg>
                          <span>Voice-overs</span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>

                {/* 3rd item */}
                <div className="md:grid md:grid-cols-12 md:gap-6 items-center">
                  {/* Image */}
                  <div
                    className="max-w-xl md:max-w-none md:w-full mx-auto md:col-span-5 lg:col-span-6 mb-8 md:mb-0 md:order-1"
                    data-aos="fade-up"
                  >
                    <Button
                      size="large"
                      variant="outlined"
                      target="_blank"
                      onClick={(e) => {
                        e.preventDefault();
                        window.open("https://fau.copal.ai/", "_blank");
                      }}
                      sx={{ marginLeft: "75px" }}
                    >
                      View Full Demo
                    </Button>
                    <span
                      style={{
                        cursor: "pointer",
                      }}
                    >
                      <>
                        <Button
                          size="large"
                          variant="outlined"
                          target="_blank"
                          onClick={handleClickOpen}
                          sx={{ marginLeft: "35px" }}
                        >
                          View Key Features
                        </Button>
                        <Dialog
                          open={open}
                          onClose={handleClose}
                          PaperComponent={PaperComponent}
                          aria-labelledby="draggable-dialog-title"
                        >
                          <CoAccessibilityComponent />
                        </Dialog>
                      </>
                    </span>
                  </div>

                  {/* Content */}
                  <div
                    className="max-w-xl md:max-w-none md:w-full mx-auto md:col-span-7 lg:col-span-6"
                    data-aos="fade-right"
                  >
                    <div className="md:pr-4 lg:pr-12 xl:pr-16">
                      <div
                        style={{
                          fontSize: "25px",
                          fontWeight: "bold",
                          marginBottom: "15px",
                        }}
                      >
                        Smart Coursepack
                      </div>

                      <ul className="text-lg text-gray-400 -mb-2">
                        <li className="flex items-center mb-2">
                          <svg
                            className="w-3 h-3 fill-current text-green-500 mr-2 shrink-0"
                            viewBox="0 0 12 12"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
                          </svg>
                          <span>
                            Automated wizard for book or course creation
                          </span>
                        </li>
                        <li className="flex items-center mb-2">
                          <svg
                            className="w-3 h-3 fill-current text-green-500 mr-2 shrink-0"
                            viewBox="0 0 12 12"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
                          </svg>
                          <span>50X less time & cost, more efficient</span>
                        </li>
                        <li className="flex items-center">
                          <svg
                            className="w-3 h-3 fill-current text-green-500 mr-2 shrink-0"
                            viewBox="0 0 12 12"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
                          </svg>
                          <span>Searchability & analytics</span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default Portfolio;
