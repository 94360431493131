import React from "react";
import TeamMember from "./TeamMember";
import NavBar from "./Navbar/NavBar";
import { Grid } from "@mui/material";
import FRITZ_PHOTO from "../images/fritz.png";

const teamData = [
  {
    name: "Fritz Schmid",
    designation:
      "Co-Founder/Engineering Lead | MS CS, Friedrich-Alexander-Universität Erlangen-Nürnberg",
    description:
      "A dynamic engineer currently pursuing a master's degree from FAU, Germany, is a passionate technology enthusiast. He thrives on coding and product development, turning ideas into practical solutions. Fritz's tech prowess and dedication make him a rising star set to impact the industry.",
    imageUrl: FRITZ_PHOTO,
    linkedinUrl: "https://www.linkedin.com/in/fritz-schmid-71b059186/",
  },
  {
    name: "Prof. Nitin Agarwal",
    designation:
      "Co-Founder/University Lead | Professor, PhD, San Jose State University",
    description:
      "Nitin holds a Ph.D. in business administration earned from Texas Tech University and possesses a profound expertise in Ed-Tech Technology, as well as policies and procedures. With a remarkable tenure spanning over 16 years at SJSU, he exhibits a deep-seated passion for Ed-Tech.",
    imageUrl:
      "https://media.licdn.com/dms/image/C5603AQG8Wx4HPrnzJw/profile-displayphoto-shrink_800_800/0/1582236295706?e=1700092800&v=beta&t=owNfo780oTvwwFA5Xf5BFwmD1FiNIV83TXlRKaugGaQ", // Add the image file for John Doe in your project's public folder
    linkedinUrl: "https://www.linkedin.com/in/nitin-aggarwal-721b073/",
  },
  {
    name: "Abhishek Chugg",
    designation:
      "Technical Engineering Support | MS CS, Carnegie Mellon University",
    description:
      "A distinguished graduate with an MS in Computer Science from Carnegie Mellon University, brings a wealth of experience to the table. With over a decade of experience at both Adobe and Google, Abhishek is leveraging his expertise to support and advance the FERPA product.",
    imageUrl:
      "https://media.licdn.com/dms/image/C5603AQH5fTNwG24fHg/profile-displayphoto-shrink_800_800/0/1601406761997?e=1700092800&v=beta&t=hkFEIBfcZIdK2866ye2Ls6ubXUGMzVDVgE0Jeju5kS0", // Add the image file for John Doe in your project's public folder
    linkedinUrl: "https://www.linkedin.com/in/abhishekchugh/",
  },
  {
    name: "Prof. Dirk Holtbrügge",
    designation:
      "German Lead | Dean, International, Friedrich-Alexander-Universität Erlangen-Nürnberg",
    description:
      "Dirk is the lead of Copal.ai's German operations and is renowned for his extensive contributions in the Ed-Tech field. He has authored over 15 books and published more than 80 research papers, solidifying his status as an Ed-Tech domain expert.",
    imageUrl:
      "https://media.licdn.com/dms/image/C4E03AQH7DXIkzgssuA/profile-displayphoto-shrink_800_800/0/1614850492677?e=1700092800&v=beta&t=7MPqm6cVsxsJL10TDxNaVltsixCnRaiwWzXiOo3YKXo", // Add the image file for John Doe in your project's public folder
    linkedinUrl: "https://www.linkedin.com/in/dirk-holtbr%C3%BCgge-b16324208/",
  },
  {
    name: "Gurmeet Naroola",
    designation:
      "Founder/Business Lead | EPGC – GSB Stanford, MSE from San Jose State University",
    description:
      "A seasoned entrepreneur with 15+ years of experience at companies like Apple, Panasonic, SunPower, and Sanmina driving $100M+ in revenue growth. He is incredibly passionate about the Ed-Tech/AI domain and making a difference to the lives of Professors and students.",
    imageUrl:
      "https://media.licdn.com/dms/image/C5603AQGhyxQaUaphQA/profile-displayphoto-shrink_800_800/0/1590039962990?e=1700092800&v=beta&t=FmYKLNs-Cyj_poVTjZSiJIh0i5aXn9wCkjZG95ASzbM", // Add the image file for John Doe in your project's public folder
    linkedinUrl: "https://www.linkedin.com/in/gurmeet-naroola-6026551/",
  },
];

const advisorData = [
  {
    name: "Prof. Dr. Joachim Hornegger",
    designation:
      "President, Friedrich-Alexander-Universität Erlangen-Nürnberg, #1 Innovator University of Europe",
    description:
      "Dr. Hornegger is a post doctorate from Stanford, doctorate from FAU, conducted research at MIT, worked as an executive from Siemens Medical, and held various academic roles. His expertise is at in Pattern Recognition.",
    imageUrl:
      "https://media.licdn.com/dms/image/C4D03AQFf4QMMTwl9cQ/profile-displayphoto-shrink_800_800/0/1563528612106?e=1700092800&v=beta&t=ru0XZNdT86BmGTvlfwo-8U1LTDsArzYKg9zLNxoE1fs", // Add the image file for John Doe in your project's public folder
    linkedinUrl: "https://www.linkedin.com/in/joachim-hornegger-b692ba2/",
  },
  {
    name: "Prof. Dan Moshavi",
    designation: "Dean, San Diego State University, Fowler College of Business",
    description:
      "Prof Moshavi has a Ph.D. in Management from the University of Oregon, along with an MBA from George Washington University. His areas of expertise are strategic leadership, workplace attitudes and service management.",
    imageUrl:
      "https://media.licdn.com/dms/image/C5603AQE0OgNd7aW1Qg/profile-displayphoto-shrink_800_800/0/1663972843847?e=1700092800&v=beta&t=5nLwCpHfA7Hlwo01I_Gk0IjbiOR7S4zMIJSPIY2y4rI", // Add the image file for John Doe in your project's public folder
    linkedinUrl: "https://www.linkedin.com/in/dan-moshavi-3360159/",
  },
];

function TeamPage() {
  return (
    <>
      <NavBar />
      <div className="my-4 py-4" data-aos="zoom-in">
        <h2
          className="my-2 text-center text-3xl text-blue-900 uppercase font-bold"
          style={{ marginTop: "50px" }}
        >
          team
        </h2>

        <div className="flex justify-center">
          <div className="w-24 border-b-4 border-blue-900"></div>
        </div>
        <h2 className="mt-4 mx-12 text-center text-xl lg:text-2xl font-semibold text-blue-900">
          We combine German engineering excellence and Silicon Valley
          entrepreneurial spirit
        </h2>
      </div>
      <div
        style={{
          marginTop: "50px",
        }}
        data-aos="zoom-in"
        data-aos-delay="100"
      >
        <Grid
          container
          direction="row"
          md={12}
          sx={{
            justifyContent: "space-evenly",
          }}
        >
          {teamData.map((member, index) => (
            <Grid item md={4}>
              <TeamMember
                key={index}
                name={member.name}
                designation={member.designation}
                description={member.description}
                imageUrl={member.imageUrl}
                linkedinUrl={member.linkedinUrl}
              />
            </Grid>
          ))}
        </Grid>
      </div>
      <div className="my-4 py-4" data-aos="zoom-in">
        <h2
          className="my-2 text-center text-3xl text-blue-900 uppercase font-bold"
          style={{ marginTop: "50px" }}
        >
          board of advisors
        </h2>

        <div className="flex justify-center">
          <div className="w-24 border-b-4 border-blue-900"></div>
        </div>
      </div>
      <div
        style={{
          marginTop: "50px",
        }}
        data-aos="zoom-in"
        data-aos-delay="100"
      >
        <Grid
          container
          direction="row"
          md={12}
          sx={{
            justifyContent: "space-evenly",
          }}
        >
          {advisorData.map((member, index) => (
            <Grid
              item
              md={4}
              sx={{
                marginBottom: "50px",
              }}
            >
              <TeamMember
                key={index}
                name={member.name}
                designation={member.designation}
                description={member.description}
                imageUrl={member.imageUrl}
                linkedinUrl={member.linkedinUrl}
              />
            </Grid>
          ))}
        </Grid>
      </div>
    </>
  );
}

export default TeamPage;
