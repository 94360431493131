import React from "react";
import { HashLink } from "react-router-hash-link";

const NavLinks = ({ currentRoute }) => {
  return (
    <>
      <HashLink
        className={
          currentRoute === ""
            ? "px-1 font-extrabold text-blue-900"
            : "px-1 font-extrabold text-gray-500 hover:text-blue-900"
        }
        smooth
        to="/"
      >
        Our Mission
      </HashLink>
      <HashLink
        className={
          currentRoute === "products"
            ? "px-1 font-extrabold text-blue-900"
            : "px-1 font-extrabold text-gray-500 hover:text-blue-900"
        }
        smooth
        to="/products"
      >
        Our Products
      </HashLink>
      <HashLink
        className={
          currentRoute === "customers"
            ? "px-1 font-extrabold text-blue-900"
            : "px-1 font-extrabold text-gray-500 hover:text-blue-900"
        }
        to="/customers"
      >
        Our Customers and Partners
      </HashLink>
      <HashLink
        className={
          currentRoute === "team"
            ? "px-1 font-extrabold text-blue-900"
            : "px-1 font-extrabold text-gray-500 hover:text-blue-900"
        }
        to="/team"
      >
        Our Team
      </HashLink>
      <HashLink
        className={
          currentRoute === "patents"
            ? "px-1 font-extrabold text-blue-900"
            : "px-1 font-extrabold text-gray-500 hover:text-blue-900"
        }
        to="/patents"
      >
        Our Patents
      </HashLink>
      <HashLink
        className={
          currentRoute === "media"
            ? "px-1 font-extrabold text-blue-900"
            : "px-1 font-extrabold text-gray-500 hover:text-blue-900"
        }
        to="/media"
      >
        Our Media Coverage
      </HashLink>
      <HashLink
        className={
          currentRoute === "faq"
            ? "px-1 font-extrabold text-blue-900"
            : "px-1 font-extrabold text-gray-500 hover:text-blue-900"
        }
        to="/faq"
      >
        FAQ
      </HashLink>
      <HashLink
        className={
          currentRoute === "contact"
            ? "px-1 font-extrabold text-blue-900"
            : "px-1 font-extrabold text-gray-500 hover:text-blue-900"
        }
        to="/contact"
      >
        Careers
      </HashLink>
      {/* <a
        className="px-1 font-extrabold text-gray-500 hover:text-blue-900"
        href={"https://www.copal.ai/login"}
        target="_blank"
        rel="noopener noreferrer"
      >
        Login
      </a> */}
    </>
  );
};

export default NavLinks;
