import React from "react";
import {
  Container,
  Grid,
  List,
  ListItem,
  Card,
  CardContent,
  Typography,
  Paper,
  Box,
} from "@mui/material";
import SJSU_LOGO from "../images/sjsu.png";
import MINETA_LOGO from "../images/mineta.png";
import FAU_LOGO from "../images/fau.jpeg";
import CORPORATE_LOGO from "../images/training.png";
import PUBLISHERS_LOGO from "../images/digital-book.png";
import UNIVERSITY_LOGO from "../images/school.png";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  card: {
    background: "#f7f7f7", // Change to your desired background color
    transition: "transform 0.2s ease-in-out",
    "&:hover": {
      transform: "scale(1.05)",
      background: "#e0e0e0", // Change to your desired hover background color
    },
    margin: "20px", // Replace with your desired margin value in pixels or other units
    border: "1px solid black",
    width: "65%",
    marginLeft: "20%",
  },
  title: {
    fontSize: "1.3rem",
    marginBottom: "16px", // Replace with your desired margin value
    textAlign: "center",
  },
  contactInfo: {
    marginTop: "16px", // Replace with your desired margin value
    "& a": {
      color: "#3366FF",
      textDecoration: "none",
      "&:hover": {
        textDecoration: "underline",
      },
    },
  },
}));

const categories = [
  {
    title: "Corporate Education",
    logo: CORPORATE_LOGO,
    points: [
      "Cost Savings",
      "Mass deployment of training",
      "Unification of high training standards",
    ],
  },
  {
    title: "Publishers",
    logo: PUBLISHERS_LOGO,
    points: [
      "Effortless transformation of videos",
      "Democratization of training",
    ],
  },
  {
    title: "Universities and Students",
    logo: UNIVERSITY_LOGO,
    points: [
      "Individualized learning experience",
      "Integration of different learning tools",
      "Interaction with other participants",
    ],
  },
];

const logos = [SJSU_LOGO, MINETA_LOGO, FAU_LOGO];

const Customers = () => {
  const classes = useStyles();

  return (
    <Container maxWidth="lg" data-aos="zoom-in" data-aos-delay="100">
      <Grid container spacing={4}>
        {/* Left Column */}
        <Grid item xs={12} md={6}>
          {categories.map((category, index) => (
            <Paper
              elevation={2}
              sx={{ padding: "16px", margin: "25px" }}
              key={index}
            >
              <Grid container spacing={2}>
                <Grid item xs={12} md={4}>
                  <Box
                    // display="flex"
                    alignItems="center"
                    justifyContent="center"
                    height={100}
                    width={100}
                    sx={{
                      margin: "15%",
                    }}
                  >
                    <img
                      src={category.logo}
                      alt={category.title}
                      style={{
                        maxWidth: "50%",
                        maxHeight: "50%",
                        marginLeft: "10%",
                      }}
                    />
                    <p style={{ fontSize: "17px" }}>{category.title}</p>
                  </Box>
                </Grid>
                <Grid item xs={12} md={8}>
                  <List>
                    {category.points.map((point, pointIndex) => (
                      <ListItem key={pointIndex}>
                        <span>
                          <span>&#10003;</span>&nbsp;&nbsp;
                          {point}
                        </span>
                      </ListItem>
                    ))}
                  </List>
                </Grid>
              </Grid>
            </Paper>
          ))}
        </Grid>

        {/* Right Column */}
        <Grid item xs={12} md={6}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            {logos.map((logo, index) => (
              <img
                key={index}
                src={logo}
                alt={`Logo ${index + 1}`}
                style={{
                  width: index === 0 ? "225px" : index === 2 ? "350px" : "250px",
                  height: index === 0 ? "175px" : "75px",
                  marginBottom: index === 0 ? "-25px" : "30px",
                }}
              />
            ))}
          </div>
          {/* contact card */}
          <Card className={classes.card}>
            <CardContent>
              <Typography variant="h6" className={classes.title}>
                Interested in collaborating with us?
              </Typography>
              <div className={classes.contactInfo}>
                <Typography variant="body1">
                  📧&nbsp;&nbsp;Email:{" "}
                  <a href="mailto:info@copal.ai">info@copal.ai</a>
                </Typography>
                <Typography variant="body1">
                  📞&nbsp;&nbsp;Phone:{" "}
                  <a href="tel:408-931-0944">408-931-0944</a>
                </Typography>
              </div>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
};

export default Customers;
