import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import "./index.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
// All pages
import Home from "./pages/Home";
import Contact from "./pages/Contact";

import { useDocTitle } from "./landing_new/CustomHook";
import FAQs from "./landing_new/faqs";
import TeamPage from "./landing_new/TeamPage";

import ScrollToTop from "./landing_new/ScrollToTop";
import Portfolio from "./landing_new/Portfolio";
import Services from "./landing_new/Services";
import Patents from "./landing_new/Patents";
import Media from "./landing_new/Media";

function App() {
  useEffect(() => {
    const aos_init = () => {
      AOS.init({
        once: true,
        duration: 1000,
        easing: "ease-out-cubic",
      });
    };

    window.addEventListener("load", () => {
      aos_init();
    });
  }, []);

  useDocTitle("Copal.ai");

  return (
    <>
      <Router>
        <ScrollToTop>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/faq" element={<FAQs />} />
            <Route path="/team" element={<TeamPage />} />
            <Route path="/products" element={<Portfolio />} />
            <Route path="/customers" element={<Services />} />
            <Route path="/patents" element={<Patents />} />
            <Route path="/media" element={<Media />} />
          </Routes>
        </ScrollToTop>
      </Router>
    </>
  );
}

export default App;
