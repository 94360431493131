import styled from "styled-components";
import React, { useState } from "react";
import { Grid } from "@mui/material";
import ReactPlayer from "react-player/lazy";
import smileyLogo from "../assets/Landing_Page_Button_Icons/Def_Face_Blur_Icon.png";
import blurFaceLogo from "../assets/Landing_Page_Button_Icons/Def_Emoji_Icon.png";
import voiceModulationLogo from "../assets/Landing_Page_Button_Icons/Def_Change_Audio_Icon.png";
import smileyLogoSel from "../assets/Landing_Page_Button_Icons/Sel_Face_Blur_Icon.png";
import blurFaceLogoSel from "../assets/Landing_Page_Button_Icons/Sel_Emoji_Icon.png";
import voiceModulationLogoSel from "../assets/Landing_Page_Button_Icons/Sel_Change_Audio_Icon.png";
import volumemute from "../assets/Landing_Page_Button_Icons/volumemute.png";
import volumeunmute from "../assets/Landing_Page_Button_Icons/volumeunmute.png";

const CoPrivacyComponent = (props) => {
  const [mute, setMute] = React.useState(true);
  const [isEmoji, setIsEmoji] = useState(false);
  const [isBlurred, setIsBlur] = useState(false);
  const [isVoiceModulated, setIsVoiceModulated] = useState(false);
  const [isPlayingCoPrivacy, setIsPlayingCoPrivacy] = useState(false);

  const onMute = () => {
    setMute(!mute);
  };

  const handleSmiley = (e) => {
    e.preventDefault();
    setIsEmoji(true);
    setIsBlur(false);
    setIsVoiceModulated(false);
    setIsPlayingCoPrivacy(true);
  };

  const handleBlurFace = (e) => {
    e.preventDefault();
    setIsBlur(true);
    setIsEmoji(false);
    setIsVoiceModulated(false);
    setIsPlayingCoPrivacy(true);
  };

  const handleVoiceModulation = (e) => {
    e.preventDefault();
    setIsVoiceModulated(true);
    setIsEmoji(false);
    setIsBlur(false);
    setIsPlayingCoPrivacy(true);
  };

  return (
    <>
      <div
        style={{
          alignItems: "center",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Group18>
          {isEmoji ? (
            <ReactPlayer
              playing={isPlayingCoPrivacy}
              width="100%"
              height="100%"
              setVolume={0.25}
              muted={mute}
              url={
                "https://firebasestorage.googleapis.com/v0/b/copal-e5764.appspot.com/o/public%2FCoPrivacy_Emoji.mp4?alt=media&token=87086e45-5a72-4375-b8b6-0948d1f089a7"
              }
            />
          ) : isBlurred ? (
            <ReactPlayer
              playing={isPlayingCoPrivacy}
              width="100%"
              height="100%"
              setVolume={0.25}
              muted={mute}
              url={
                "https://firebasestorage.googleapis.com/v0/b/copal-e5764.appspot.com/o/public%2FCoPrivacy_FaceBlur.mp4?alt=media&token=539e8e52-1fe8-4894-a779-d64723e54099"
              }
            />
          ) : isVoiceModulated ? (
            <ReactPlayer
              playing={isPlayingCoPrivacy}
              width="100%"
              height="100%"
              setVolume={0.25}
              muted={mute}
              url={
                "https://firebasestorage.googleapis.com/v0/b/copal-e5764.appspot.com/o/public%2FCoPrivacy_ChangeAudio.mp4?alt=media&token=eeacfa7a-62f0-4e55-a82f-09f26b8451b1"
              }
            />
          ) : (
            <ReactPlayer
              playing={true}
              loop={true}
              width="100%"
              height="100%"
              setVolume={0.25}
              muted={mute}
              autoPlay
              url={
                "https://firebasestorage.googleapis.com/v0/b/copal-e5764.appspot.com/o/public%2FCoPrivacy_Regular.mp4?alt=media&token=e382b18c-96d5-4b08-b613-95dfac5f081e"
              }
            />
          )}
        </Group18>
      </div>
      <Grid
        container
        direction="row"
        md={12}
        sx={{
          marginLeft: "20%",
        }}
      >
        <Grid
          item
          md={3}
          aria-label="Mute/Unmute"
          style={{
            position: "absolute",
            width: "40px",
            height: "40px",
            color: "black",
            marginRight: "25px",
            marginTop: "22px",
          }}
          onClick={onMute}
        >
          {mute ? (
            <img
              name="Volume Mute"
              alt="Volume Mute"
              src={volumemute}
              style={{
                width: "40px",
                height: "40px",
                cursor: "pointer",
              }}
            />
          ) : (
            <img
              name="Volume Unmute"
              alt="Volume Unmute"
              src={volumeunmute}
              style={{
                width: "40px",
                height: "40px",
                cursor: "pointer",
              }}
            />
          )}
        </Grid>
        <Grid item md={9}>
          <Grid
            container
            direction="row"
            style={{
              display: "flex",
              alignItems: "center",
              width: "275px",
              height: "75.73px",
              background: "#f7f7f7",
              borderRadius: "25px",
              marginLeft: "50px",
              border: "0.5px solid black",
            }}
          >
            <Grid
              item
              md={4.5}
              style={{
                fontFamily: "Poppins",
                fontStyle: "normal",
                fontWeight: 700,
                fontSize: "15px",
                lineHeight: "40px",
                letterSpacing: "0.424856px",
                color: "black",
                opacity: "0.5",
              }}
            >
              &nbsp;&nbsp;&nbsp;&nbsp;Try it Out
            </Grid>
            <Grid
              item
              container
              direction="row"
              md={7.5}
              style={{
                width: "43.73px",
                height: "43.73px",
                justifyContent: "space-between",
                paddingRight: "15px",
              }}
            >
              <Grid item>
                {isEmoji ? (
                  <img
                    name="Unselect Emoji"
                    alt="Unselect Emoji"
                    src={blurFaceLogoSel}
                    style={{
                      width: "43.73px",
                      height: "43.73px",
                      cursor: "pointer",
                    }}
                    onClick={handleSmiley}
                  />
                ) : (
                  <img
                    name="Select Emoji"
                    alt="Select Emoji"
                    src={blurFaceLogo}
                    style={{
                      width: "43.73px",
                      height: "43.73px",
                      cursor: "pointer",
                    }}
                    onClick={handleSmiley}
                  />
                )}
              </Grid>
              <Grid item>
                {isBlurred ? (
                  <img
                    name="Unblur"
                    alt="Unblur"
                    src={smileyLogoSel}
                    style={{
                      width: "43.73px",
                      height: "43.73px",
                      cursor: "pointer",
                    }}
                    onClick={handleBlurFace}
                  />
                ) : (
                  <img
                    name="Blur"
                    alt="Blur"
                    src={smileyLogo}
                    style={{
                      width: "43.73px",
                      height: "43.73px",
                      cursor: "pointer",
                    }}
                    onClick={handleBlurFace}
                  />
                )}
              </Grid>
              <Grid item>
                {isVoiceModulated ? (
                  <img
                    name="Voice Modulated"
                    alt="Voice Modulated"
                    src={voiceModulationLogoSel}
                    style={{
                      width: "43.73px",
                      height: "43.73px",
                      cursor: "pointer",
                    }}
                    onClick={handleVoiceModulation}
                  />
                ) : (
                  <img
                    name="Voice not Modulated"
                    alt="Voice not Modulated"
                    src={voiceModulationLogo}
                    style={{
                      width: "43.73px",
                      height: "43.73px",
                      cursor: "pointer",
                    }}
                    onClick={handleVoiceModulation}
                  />
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default CoPrivacyComponent;

const Group18 = styled.div`
  width: 500px;
  position: relative;
`;
