import React from "react";
import "./testimonials.css";

const Testimonials = () => {
  const testimonialsData = [
    {
      id: 1,
      photo:
        "https://media.licdn.com/dms/image/C4D03AQFf4QMMTwl9cQ/profile-displayphoto-shrink_800_800/0/1563528612106?e=1700092800&v=beta&t=ru0XZNdT86BmGTvlfwo-8U1LTDsArzYKg9zLNxoE1fs",
      name: "Prof. Dr. Joachim Hornegger",
      designation: "President of FAU, #1 Innovator University of Europe",
      content:
        "We have experienced an unprecedented change in the Ed-Tech space over the last 4 years, first with covid and now with the emergence of Artificial Intelligence. Online learning will never be the same again. There is a new era of learning and teaching. Smart tools are the future, and we must do it now, not 5 years later.",
      flag: false,
    },
    {
      id: 2,
      photo:
        "https://media.licdn.com/dms/image/C5603AQE0OgNd7aW1Qg/profile-displayphoto-shrink_800_800/0/1663972843847?e=1700092800&v=beta&t=5nLwCpHfA7Hlwo01I_Gk0IjbiOR7S4zMIJSPIY2y4rI",
      name: "Prof. Dan Moshavi",
      designation:
        "Dean, San Diego State University, Fowler College of Business",
      content: "Copal has created the smart tools for the future of education.",
      flag: true,
    },
  ];

  return (
    <div className="testimonials-container">
      {testimonialsData.map((testimonial) => (
        <div
          key={testimonial.id}
          className="testimonial"
          style={{
            marginLeft: testimonial.flag ? "35%" : 0,
            marginRight: !testimonial.flag ? "25%" : 0,
            marginTop: !testimonial.flag ? "10%" : "0px",
          }}
        >
          <div className="testimonial-photo-container">
            <img
              src={testimonial.photo}
              alt={testimonial.name}
              className="testimonial-photo"
            />
          </div>
          <div className="testimonial-content">
            <div className="testimonial-author">
              <div className="testimonial-name">{testimonial.name}</div>
              <div className="testimonial-designation">
                {testimonial.designation}
              </div>
            </div>
            <div className="testimonial-text">
              <span className="quotation-mark">"</span>
              {`${testimonial.content}`}
              <span className="quotation-mark">"</span>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default Testimonials;
